<template>
  <div
    :style="{ order: BigNumber(minedBalance!).isZero() && !claimableProducts?.length ? 1 : 0 }"
    class="building-wrapper"
  >
    <img class="building-icon" :src="`/img/buildings/${buildingKey}.png`" alt="" @click="openBuilding" />
    <div class="building-board-name">{{ $t(buildingKey) }}</div>

    <div v-if="recipes && userActivity?.length" class="claim-option">
      <div class="crafting-items">
        <div v-for="product of claimableProducts.slice(0, 3)" :key="product.tokenAddress" class="claim-item">
          <img
            class="claim-image"
            :src="
              $getIconToken(
                product,
                $isShip(product) ? getShipIndexByRecipeId(BigNumber(product.recipeId).toNumber()) : undefined
              )
            "
            :alt="tokenMetaData(blockchain.contracts, product.tokenAddress).label"
          />
          <p class="claim-current">
            <span v-if="singleClaimableProductAmount && !BigNumber(singleClaimableProductAmount || 0).isZero()">
              {{ singleClaimableProductAmount }}
            </span>
          </p>
        </div>
      </div>

      <button
        class="btn-primary"
        type="button"
        @click="
          () => {
            if (!userActivity) return;
            if (userActivity.length > 1) {
              showPopupValue = true;
            } else {
              claimCraftedTokens(userActivity[0]);
            }
          }
        "
      >
        <span> {{ $t('craftingClaim') }} &nbsp; </span>
        <img :src="`/img/icons/chevronRight.svg`" alt="chevron right" />
      </button>
    </div>

    <div v-else-if="!BigNumber(minedBalance!).isZero()" class="claim-option">
      <div class="crafting-items">
        <div class="claim-item">
          <img
            class="claim-image"
            :src="
              tokens[
                building.buildingKey === 'dock'
                  ? Tokens.pearl
                  : (tokensConfig[building.miningToken!]?.name?.contract as Tokens)
              ]?.toString()
            "
            alt=""
          />
          <p class="claim-current">
            <span v-if="singleClaimableProductAmount && !BigNumber(singleClaimableProductAmount || 0).isZero()">
              {{ singleClaimableProductAmount }}
            </span>
          </p>
        </div>

        <div v-if="!BigNumber(minedExtraRewardBalance).isZero()" class="claim-item">
          <img
            class="claim-image"
            :src="
              tokens[
                tokenMetaData(
                  blockchain.contracts,
                  blockchain.contracts?.[building.miningToken!]?.addresses?.secondaryToken!
                )?.name?.contract as Tokens
              ]?.toString()
            "
            alt=""
          />
          <p class="claim-current">
            <span v-if="singleClaimableProductAmount && !BigNumber(singleClaimableProductAmount || 0).isZero()">
              {{ singleClaimableProductAmount }}
            </span>
          </p>
        </div>
      </div>

      <button
        class="btn-primary"
        type="button"
        @click="
          () => {
            if (!userActivity) return;
            if (userActivity.length > 1) {
              showPopupValue = true;
            } else {
              claimRewardHandler();
            }
          }
        "
      >
        <span> {{ $t('craftingClaim') }} &nbsp; </span>
        <img :src="`/img/icons/chevronRight.svg`" alt="chevron right" />
      </button>
    </div>

    <div v-else-if="products?.length" class="recipes-box" @click="openBuilding">
      <div v-for="(product, index) of products.slice(0, 3)" :key="index" class="recipe">
        <img
          class="crafting-item-image"
          :src="
            $getIconToken(
              product,
              $isShip(product) ? getShipIndexByRecipeId(BigNumber(product.recipeId).toNumber()) : undefined
            )
          "
          :alt="tokenMetaData(blockchain.contracts, product.tokenAddress)?.label"
        />
      </div>
    </div>

    <div v-else-if="buildingKey === 'dock'" class="recipes-box" @click="openBuilding">
      <div class="recipe">
        <img
          class="crafting-item-image"
          :src="tokens[tokensConfig.blackPearl?.name?.contract as Tokens].toString()"
          :alt="'pearl'"
        />
      </div>
      <div class="recipe">
        <img
          class="crafting-item-image"
          :src="tokens[tokensConfig.pearl?.name?.contract as Tokens].toString()"
          :alt="'pearl'"
        />
      </div>
    </div>

    <div v-else-if="building.miningToken" class="recipes-box" @click="openBuilding">
      <div class="recipe">
        <img
          class="crafting-item-image"
          :src="
            tokens[
              tokenMetaData(blockchain.contracts, blockchain.contracts[building.miningToken].addresses.secondaryToken!)
                ?.name?.contract as Tokens
            ]?.toString()
          "
          :alt="'pearl'"
        />
      </div>
      <div class="recipe">
        <img
          class="crafting-item-image"
          :src="tokens[tokensConfig[building.miningToken]?.name?.contract as Tokens].toString()"
          :alt="'pearl'"
        />
      </div>
    </div>

    <el-dialog
      v-model="showPopupValue"
      :title="$t(buildingKey)"
      :fullscreen="$device.isMobile"
      :append-to-body="true"
      :class="{ 'ru-locale': $i18n.locale === 'ru' }"
    >
      <div class="claimItemsWrapper">
        <CraftingReadyForClaimMaterials
          v-for="activityItem of userActivity"
          :key="activityItem.id"
          :activity-item="activityItem"
          :token-metadata-computed="
            tokenMetaData(blockchain.contracts, claimableProducts?.[0].tokenAddress || ZeroHash)
          "
          :selected-recipe-id="recipes?.[activityItem.index]?.recipeId || ''"
          :recipe="recipes?.[activityItem.index]"
          :disabled="isClaiming"
          @claim-load-state-changed="
            (isLoading) => {
              if (!isLoading) showPopupValue = false;
              isClaiming = isLoading;
            }
          "
        />
      </div>
    </el-dialog>

    <el-dialog
      v-model="showBuyPearlModal"
      class="crafting"
      :class="{ 'ru-locale': $i18n.locale === 'ru' }"
      :fullscreen="true"
      :title="$t(building?.buildingKey || '')"
      :append-to-body="true"
      :center="true"
    >
      <BuyPearlModal :token="Tokens.pearl" :is-building="true" />
    </el-dialog>

    <el-dialog
      v-model="showBuyLicenseModal"
      class="crafting"
      :class="{ 'ru-locale': $i18n.locale === 'ru' }"
      :fullscreen="$device.isMobile"
      :title="$t(building?.buildingKey || '')"
      :append-to-body="true"
      :center="true"
    >
      <BuyLicenseModal :token="building.miningToken!" :description="$t(building.descriptionKey)" :is-building="true" />
    </el-dialog>

    <el-dialog
      v-model="showCraftingModal"
      class="crafting produce"
      :class="{ 'ru-locale': $i18n.locale === 'ru' }"
      :fullscreen="true"
      :title="$t(building?.buildingKey || '')"
      :append-to-body="true"
      :center="true"
      destroy-on-close
    >
      <CraftingModal :selected-building="building" />
    </el-dialog>
  </div>
</template>

<script setup lang="ts">
import type { BuildingProps, RecipeProduct, RecipeProps, UserActivityAvailableForClaim } from '~/types/crafting';
import { computed, defineProps, watch } from 'vue';
import { useWalletInfo, useWeb3ModalAccount } from '@web3modal/ethers/vue';
import getUserActivities from '~/utils/userActivityManager';
import { BigNumber } from 'bignumber.js';
import { tokenMetaData } from '~/utils';
import { AbiCoder, formatEther, ZeroHash } from 'ethers';
import { Tokens, tokensConfig } from '~/utils/constants';
import tokens from '~/public/img/tokens';

const { apiUrl, blockchain } = useEnvs();
const { getContractReadOnly, getContract } = useAbiAccess();
const { address } = useWeb3ModalAccount();
const { walletInfo } = useWalletInfo();
const { $errorHandler } = useNuxtApp();
const { earned } = useTokensReader();

const { sendContractMethod } = useSendContractMethod();
const { t } = useI18n();
const store = useMainStore();
const { claimReward } = useStakingMethods();
const { claimMinedTokens } = useMineLicense();

const craftingContract = await getContract('crafting', blockchain.contracts.crafting);

const showPopupValue = ref(false);
const showCraftingModal = ref(false);
const showBuyPearlModal = ref(false);
const showBuyLicenseModal = ref(false);

const isClaiming = ref(false);

const selectedRecipeId = ref<string>();
const products = ref<(RecipeProduct & { recipeId: RecipeProps['recipeId'] })[]>([]);
const userActivity = ref<UserActivityAvailableForClaim[]>();
const building = computed(() => props.building);
const buildingHash = computed(() => props.building.buildingHash || ZeroHash);
const buildingKey = computed(() => props.building.buildingKey || '');
const isLoadingClaimButton = ref(false);
const confirmationNumberRef = ref(0);
const minedBalance = ref('0');
const minedExtraRewardBalance = ref('0');

provide('building', building);
const props = defineProps<{
  building: BuildingProps;
}>();

watch(
  [address, () => props.building.miningToken, () => store.notificationUpdateVersion],
  async ([newAddress, newToken]) => {
    if (!newAddress || (!newToken && props.building.buildingKey !== 'dock')) {
      minedBalance.value = '0';
      return;
    }

    if (props.building.buildingKey === 'dock') {
      minedBalance.value = await earned(newAddress, Tokens.pearl);
      minedExtraRewardBalance.value = '0';
      return;
    }
    const mineContract = await getContractReadOnly('mine', blockchain.contracts[newToken!].addresses.mine || '');
    const minedContractBalance = await mineContract.mined(newAddress);

    minedBalance.value = Number(formatEther(minedContractBalance[0])).toFixed(2);
    minedExtraRewardBalance.value = Number(formatEther(minedContractBalance[1])).toFixed(2);
  },
  { immediate: true }
);

const claimableProducts = computed(() => {
  return products.value?.filter((product) =>
    userActivity.value?.some((activity) =>
      recipes.value?.[activity.index].products.some(
        (recipeProduct) => recipeProduct.tokenAddress === product.tokenAddress
      )
    )
  );
});

// TODO: refactoring copy paste in MobileBuildingRecipeBoard
const singleClaimableProductAmount = computed(() => {
  if (!claimableProducts.value || (claimableProducts.value && claimableProducts.value?.length > 1)) {
    return 0;
  }

  const isNFT = tokenMetaData(blockchain.contracts, claimableProducts.value[0]?.tokenAddress)?.interface === 'erc721';

  const minValue = userActivity.value?.reduce(
    (acc, currentValue) =>
      BigNumber(acc)
        .plus(
          BigNumber(currentValue.available).multipliedBy(
            recipes.value?.[currentValue.index].products[0].quantityMin || 0
          )
        )
        .decimalPlaces(1)
        .toNumber(),
    0
  );

  const maxValue = userActivity.value?.reduce(
    (acc, currentValue) =>
      BigNumber(acc)
        .plus(
          BigNumber(currentValue.available).multipliedBy(
            recipes.value?.[currentValue.index].products[0].quantityMax || 0
          )
        )
        .decimalPlaces(1)
        .toNumber(),
    0
  );

  if (!BigNumber(minedBalance.value).isZero() && BigNumber(minedExtraRewardBalance.value).isZero()) {
    return BigNumber(minedBalance.value).decimalPlaces(2).toNumber();
  }

  // Return only maximum if min and max is equal
  if (!isNFT && minValue === maxValue) {
    return maxValue;
  }

  return isNFT
    ? userActivity.value?.reduce((acc, currentValue) => acc + currentValue.available, 0)
    : `${minValue} - ${maxValue}`;
});

const { data: recipes } = await useFetch<RecipeProps[]>(() => `/crafting/buildings/${buildingHash.value}/recipes`, {
  baseURL: apiUrl,
  watch: [buildingHash],
  key: `buildingRecipes${buildingHash.value}`,

  onResponse: ({ response }) => {
    const allProducts: (RecipeProduct & { recipeId: RecipeProps['recipeId'] })[] = response._data
      .filter((recipe: RecipeProps) => recipe.products.length)
      .map((recipe: RecipeProps) => recipe.products.map((product) => ({ ...product, recipeId: recipe.recipeId })));
    const productsSet: (RecipeProduct & {
      recipeId: RecipeProps['recipeId'];
    })[] = [];

    allProducts.flat().forEach((product) => {
      if (!productsSet.some((item) => item.tokenAddress === product.tokenAddress)) {
        productsSet.push(product);
        products.value = products.value.concat(product);
      }
    });
    selectedRecipeId.value = response._data[0]?.recipeId;
  }
});

watch(
  [address, () => store.notificationUpdateVersion],
  async ([newAddress]) => {
    if (!newAddress || !recipes.value || !craftingContract) {
      userActivity.value = [];
      return;
    }

    const formattedValue = await getUserActivities(recipes.value, props.building, newAddress, craftingContract);
    userActivity.value = formattedValue.flat();
  },
  {
    immediate: true
  }
);

const claimCraftedTokens = async (activity: UserActivityAvailableForClaim) => {
  isLoadingClaimButton.value = true;

  try {
    const claimTx = await sendContractMethod(
      {
        contract: 'crafting',
        address: blockchain.contracts.crafting,
        methodName: 'claimRecipe',
        methodArguments: [recipes.value?.[activity.index].recipeId, activity.id]
      },
      () => {
        confirmationNumberRef.value = confirmationNumberRef.value + 1;
      }
    );

    if (!claimTx?.hash) return;
    const txReceipt = await claimTx?.wait();

    if (txReceipt) {
      const abiCoder = new AbiCoder();
      const amount = formatEther(abiCoder.decode(['uint'], txReceipt.logs[0].data)[0]);

      let description: string = '';

      // if (recipes.value?.[activity.index]) {
      const methodName = tokenMetaData(
        blockchain.contracts,
        recipes.value?.[activity.index]?.products[0]?.tokenAddress!
      )?.methodName;
      const isNFT =
        tokenMetaData(blockchain.contracts, recipes.value?.[activity.index]?.products[0]?.tokenAddress!).interface ===
        'erc721';

      description = `${t(methodName || '')} ${capitalizeFirstLetter(recipes.value?.[activity.index]?.products[0] ? getNftValueByAddress(blockchain.contracts, recipes.value?.[activity.index]?.products[0]) : '')}`;
      // }

      if (!isNFT) {
        description = `(${BigNumber(amount).decimalPlaces(2)} ${t(methodName || '')})`;
      }

      saveHashToLocalStorage(`${t('dashboardPurchasesClaimed')} ${description}`, claimTx.hash);
    }

    store.updateVersion();

    await claimTx?.wait(blockchain.minConfirmationsCount);

    const confirmations = await claimTx?.confirmations();

    if (confirmations >= blockchain.minConfirmationsCount && claimTx?.hash) {
      modifyHistoryHashStatus(claimTx.hash, 'Done');
      store.updateVersion();
      confirmationNumberRef.value = 0;
    }
  } catch (error) {
    console.error(error, 'error on claim');
    ElNotification.error({
      title: '',
      message: 'Error while claiming crafted token'
    });
  } finally {
    isLoadingClaimButton.value = false;
  }
};

const claimRewardHandler = async () => {
  if (!address.value) {
    alert('Please unlock wallet!');
    return;
  }
  confirmationNumberRef.value = 0;

  try {
    isLoadingClaimButton.value = true;
    const receipt =
      buildingKey.value === 'dock'
        ? await claimReward(() => {
            confirmationNumberRef.value += 1;
          }, Tokens.pearl)
        : await claimMinedTokens(() => {
            confirmationNumberRef.value += 1;
          }, props.building.miningToken!);

    if (receipt?.hash) {
      await PearlApiService.pushActivity(apiUrl, {
        address: address.value ?? '',
        transactionHash: receipt.hash,
        value: 0,
        type: 'REWARD_CLAIM'
      });
      saveHashToLocalStorage(t('notificationClaimReward'), receipt.hash);
      store.updateVersion();

      await receipt?.wait(blockchain.minConfirmationsCount);
      const confirmations = await receipt?.confirmations();

      if (confirmations >= blockchain.minConfirmationsCount) {
        isLoadingClaimButton.value = false;
        minedBalance.value = '0';
        modifyHistoryHashStatus(receipt.hash, 'Done');
        store.updateVersion();
      }
    }
  } catch (e: unknown) {
    isLoadingClaimButton.value = false;
    if (walletInfo.value?.name) {
      const errorMessage = getCancelErrorMessage(e, walletInfo.value.name);
      $errorHandler(t('notificationFailedToUnlock') + t(errorMessage));
    }
  }
};

const openBuilding = () => {
  if (props.building.buildingKey === 'dock') {
    showBuyPearlModal.value = true;
    return;
  }

  if (props.building.miningToken) {
    showBuyLicenseModal.value = true;
    return;
  }

  if (recipes.value?.length) {
    showCraftingModal.value = true;
  }
};
</script>

<style scoped lang="scss">
.claimItemsWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
}

.building-wrapper {
  position: relative;
  height: 75px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-radius: 12px;
  border: 1px solid #1e353e;
  background: #041a27b2;
  padding: 8px;

  .building-icon {
    position: absolute;
    left: 5px;
    top: -55px;
    width: 120px;
  }

  .building-board-name {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0 14px;
    border-radius: 15px;
    border: 1px solid #1e353e;
    background: rgba(0, 0, 0, 0.8);
    font-size: 12px;
    font-weight: 600;
    line-height: 21.3px;
    color: white;
    max-width: 175px;
    text-align: center;
  }

  .btn-primary {
    position: absolute;
    bottom: 8px;
    right: 12px;
    padding: 0;
    height: 44px;
    width: 160px;

    @media (max-width: 376px) {
      width: 120px;
    }
  }

  .crafting-items {
    position: absolute;
    top: -25px;
    right: 12px;
    width: fit-content;
    height: 35px;
    background: linear-gradient(#60ff7c 40%, #04202b 130%);
    border-radius: 10px;
    border: 1px solid #ffffff;
    box-shadow: 0 0 10px #ddcead;
    display: flex;
    align-items: center;
    padding: 0 5px;

    .claim-item {
      display: flex;
      gap: 5px;

      .claim-image {
        width: 30px;

        &[alt='nftChristmasShip'] {
          width: 25px;
          height: 25px;
          border-radius: 5px;
        }

        &[alt='nftChristmasPalm'] {
          width: 25px;
          height: 25px;
        }
      }

      .claim-current {
        color: white;
        font-weight: 700;

        padding: 0;
        margin: 0;
      }
    }
  }

  .recipes-box {
    display: flex;
    gap: 10px;

    @media screen and (max-width: 376px) {
      gap: 4px;
    }

    .recipe {
      width: 52px;
      height: 52px;
      border: 1px solid #1e353e;
      border-radius: 12px;
      background-color: #041a27;
      box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.25);

      .crafting-item-image {
        width: 50px;

        &[alt='nftChristmasShip'] {
          border-radius: 5px;
        }
      }
    }
  }
}
</style>
